import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import ServiceAreas from "../../components/serviceAreas"
import Education from "../../components/education"
import SubServicesAlt from "../../components/subServicesAlt"
import Testimonials from "../../components/testimonials"
import HeroSection from "../../components/heroSection"

function WaterLineRepair() {
  const data = useStaticQuery(graphql`
    query waterLineRepairImages {
      waterLines: file(relativePath: { eq: "water-line-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Water Line Repair"
        description={`We offer thorough, fast, and affordable water line repair services. Our master plumber has over 30 years of experience in water line repair, so call now.`}
      />
      <HeroSection
        h1={`Water Line Repair`}
        h2={`Have a Master Plumber Repair Your Water Line`}
        h3={`Correctly-functioning water lines are critical to your home operating effectively. If your water lines are faulty, your home is at risk of costly water damage or expensive utility bills.`}
        p1={`Our experienced team is able to repair your water lines right the first time, so your home can have the clean, fresh water it needs. Talk with a master plumber today and receive a free quote for your water line repair.`}
        heroImg={data.waterLines.childImageSharp.fluid}
        heroImgAltTag={`Water Line Repair`}
      />
      <section>
        <div className="pb-16 px-2 md:px-0">
          <h2 className="font-bold leading-8 text-2xl mb-4">
            About Klein Plumbing Services
          </h2>
          <div className="flex flex-col-reverse lg:flex-row">
            <div className="md:w-full lg:mr-8">
              <div className="md:flex">
                <p className="text-gray-600 leading-normal text-lg mb-4"></p>
              </div>
              <div>
                <p className="text-gray-600 text-lg mb-4">
                  Klein Plumbing Services was founded by Sid Bruton in 2006 in
                  order to provide vastly superior - yet affordable - plumbing,
                  sewer, and water heater repair to the North Houston area.
                  Family owned and operated, Klein Plumbing Services has had the
                  pleasure of serving North Houston residents with quality
                  service and plumbing repairs. Our professional advice is
                  always transparent, meaning we only recommend the necessary or
                  repairs and services you really need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Education
        mainTitle={`Signs Your Water Line Needs Repair`}
        titleOne={`Loss of Water Pressure`}
        descriptionOne={`Anytime you experience a loss of water pressure, it's a symptom that there's something seriously wrong with your plumbing system. If your entire home is experiencing this issue, it could signal that you have a rupture or blockage in your water line.`}
        titleTwo={`It's Leaking`}
        descriptionTwo={`A unusually soggy patch in the ground or a pool of standing water in your home or yard is a strong indicator that you have a water line leak. To prevent water damage to your home, it should be repaired or replaced immediately.`}
        titleThree={`High Water Bills`}
        descriptionThree={`A leak in your water line can often contribute to a spike in your water utility bill. If it goes up, even though there's no apparent cause, it probably means that your water line isn't functioning correctly and needs repairs.`}
      />
      <SubServicesAlt
        mainTitle={`Our Services`}
        titleOne={`Water Line Diagnosis`}
        descriptionOne={`With proven methods and modern equipment, our experienced team is able to locate and precisely target the problem area in your water line.`}
        titleTwo={`Consultation & Documentation`}
        descriptionTwo={`When we find the source of the leak in your water main, we'll document everything we've learned and communicate with you the best way to resolve it.`}
        titleThree={`Water Line Repair`}
        descriptionThree={`Once we've discussed with you the best options, our team can repair the damaged portion of the water pipe while being as minimally invasive as possible. Once we're done, we'll put your home back like we found it.`}
      />

      <Testimonials />
      <ServiceAreas
        description={`water lines`}
        subDescription={`water line repair`}
      />
      <CTA title={`Get a Free Quote to Fix Your Water Line Now`} />
    </Layout>
  )
}

export default WaterLineRepair
